import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APITransport } from 'redux/actions';
import UserLogoutApi from 'redux/actions/api/User/UserLogout';

const AutoLogout = ({ children }) => {
    const navigate = useNavigate(); // useNavigate is the new way to navigate in v6
    const dispatch = useDispatch()
    const logoutTimer = useRef(null);
    const inactivityTimeLimit = 30 * 60 * 1000; // 30 minutes in milliseconds
    
    // Function to reset the inactivity timer
    const resetTimer = () => {
        // Clear any existing timer
        if (logoutTimer.current) clearTimeout(logoutTimer.current);
        // console.log("resetting timer");
        
        // Set a new timer that will log the user out after the inactivity limit
        logoutTimer.current = setTimeout(() => {
            // Log out the user by calling the handleLogout function
            handleLogout();
        }, inactivityTimeLimit);
    };

    // Function to handle the logout action
    const handleLogout = () => {
        // console.log('You have been logged out due to inactivity.');
        const apiObj = new UserLogoutApi();
          dispatch(APITransport(apiObj));
          localStorage.clear();
        // Navigate to the login page after logout
        navigate('/login', { replace: true }); // replace: true prevents going back to the protected page
    };

    useEffect(() => {
        // Add event listeners to detect user activity
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keypress', resetTimer);

        // Start the initial inactivity timer
        resetTimer();

        // Cleanup function to remove event listeners and timer when component unmounts
        return () => {
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keypress', resetTimer);
            if (logoutTimer.current) clearTimeout(logoutTimer.current);
        };
    }, []);

    return <>{children}</>;
};

export default AutoLogout;
