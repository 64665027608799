import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  MenuProps,
} from "utils";

//Components
import {
  Button,
  Card,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  Checkbox,
  IconButton,
} from "@mui/material";
import { CustomizedSnackbars, Loader, OutlinedTextField } from "common";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//APIs
import {
  APITransport,
} from "redux/actions";
import AddInvitedUserData from "redux/actions/api/User/AddInvitedUserData";

const indianLanguages = [
    'Assamese',
    'Bengali',
    'English',
    'Gujarati',
    'Hindi',
    'Kannada',
    'Kashmiri',
    'Konkani',
    'Malayalam',
    'Manipuri',
    'Marathi',
    'Nepali',
    'Odia',
    'Punjabi',
    'Sanskrit',
    'Sindhi',
    'Tamil',
    'Telugu',
    'Urdu',
  ];
const InvitedUsersData = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [snackbar, setSnackbarInfo] = useState({
    open: false,
    message: "",
    variant: "",
  });

  const apiStatus = useSelector((state) => state.apiStatus);
  const userData = useSelector((state) => state.getLoggedInUserDetails.data);

  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    password:"",
    showPassword: false,
    email:"",
    first_name:"",
    last_name:"",
    phone:"",
    languages:[],
  });
 

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
        ...prevState,
        [name]: value, 
      }));
  };



  const handleFieldChange = (event) => {
    event.preventDefault();
    setUserDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    const body = {
        username: userDetails.username,
      email: userDetails.email,
      password: userDetails.password,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      phone: userDetails.phone,
      languages: userDetails.languages,
    };

    console.log(apiStatus,"bodyy");
    

    const userObj = new AddInvitedUserData(id, body);
    dispatch(APITransport(userObj));
  };

  const handleClickShowPassword = () => {
    setUserDetails({ ...userDetails, showPassword: !userDetails.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderSnackBar = useCallback(() => {
    return (
      <CustomizedSnackbars
        open={snackbar.open}
        handleClose={() =>
          setSnackbarInfo({ open: false, message: "", variant: "" })
        }
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        variant={snackbar.variant}
        message={[snackbar.message]}
      />
    );
  }, [snackbar]);

  console.log(apiStatus,"apistatus");

  useEffect(() => {
    if (apiStatus && apiStatus.apiType === "ADD_INVITED_USER_DATA") {
          if(apiStatus.errors===true){
            setSnackbarInfo({
              open: true,
              message: apiStatus?.message,
              variant: "error",
            });
           } else {
            setSnackbarInfo({
              open: true,
              message: apiStatus?.message,
              variant: "success",
            });
                }
    }
}, [apiStatus])



  return (
    <>
    {renderSnackBar()}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{height:"100vh"}}
      >
        <Box
          sx={{
            width: "70%",
            minHeight: 500,
            padding: 5,
            border: 0,
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            boxShadow:2,          
        }}
        
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h3" align="center">
                User Information
              </Typography>
            </Grid>
       
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Enter Username"
                name="username"
                value={userDetails?.username}
                onChange={handleFieldChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Enter Email "
                name="email"
                value={userDetails?.email}
                onChange={handleFieldChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/* <TextField
                variant="outlined"
                fullWidth
                label="Enter password "
                name="password"
                value={userDetails?.password}
                onChange={handleFieldChange}
                InputLabelProps={{ shrink: true }}
              /> */}
               <OutlinedTextField
            fullWidth
            name="password"
            type={userDetails.showPassword ? "text" : "password"}
            onChange={handleFieldChange}
            value={userDetails?.password}
            // placeholder=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {userDetails.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
            </Grid>
            

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Enter First Name "
                name="first_name"
                value={userDetails?.first_name}
                onChange={handleFieldChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Enter Last Name "
                name="last_name"
                value={userDetails?.last_name}
                onChange={handleFieldChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Enter Phone Number "
                name="phone"
                value={userDetails?.phone}
                onChange={handleFieldChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl fullWidth>
                <InputLabel id="transcription-source-type">
                Languages
                </InputLabel>
                <Select
                  labelId="transcription-source-type"
                  id="transcription-source-type_select"
                  value={userDetails?.languages || []}
                  name="languages"
                  label="Languages"
                  placeholder="Languages"
                  MenuProps={MenuProps}
                  multiple
                  onChange={handleSelectChange}
                  sx={{ textAlign: "left" }}
                >
                  {indianLanguages.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                style={{ marginTop: 20 }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit()}
                  disabled={!userDetails?.username || !userDetails?.email || !userDetails?.password || !userDetails?.first_name || !userDetails?.last_name || !userDetails?.phone || !userDetails?.languages?.length}
                  style={{ borderRadius: 6 }}
                >
                  Add User Data{" "}
                  {apiStatus.loading && (
                    <Loader size={20} margin="0 0 0 10px" color="secondary" />
                  )}
                </Button>
              </Grid>

           {apiStatus.apiType === "ADD_INVITED_USER_DATA" && apiStatus.errors === false &&    <Grid
                container
                direction="row"
                justifyContent="center"
                style={{ marginTop: 30, fontSize:"20px" }}


              >
                Please enter the credentials in the following link to login :&nbsp; <a href="https://chitraanuvaad.bhashini.co.in/#/login">https://chitraanuvaad.bhashini.co.in/#/login</a>
                </Grid>
              }
              </Grid>
       
        </Box>
      </Grid>
    </>
  );
};

export default InvitedUsersData;
